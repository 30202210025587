@import url(https://fonts.googleapis.com/css2?family=Oswald:wght@300&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@500&display=swap);
html,
body {
  font-family: 'Oswald', sans-serif;
  width: 100%;
  height: 100%;
  margin: 0px;
  padding: 0px;
  overflow-x: hidden;
}

.home {
  height: 200vh;
  position: relative;
  display: flex;
  flex-direction: column;


}

.h1 {
  height: 100vh;
  background-color: #FFD6EE;
  display: flex;
  flex-direction: column;
  position: relative;
}

.h2 {
  height: 100vh;
}

.navbar {
  /* margin-: 150px; */
  display: flex;
  height: 8vh;
  background-color: #FFD6EE;
  width: 100%;
  justify-content: end;
}

.nav-el {
  /* flex: ; */
  text-decoration: none;
  margin: 10px;
  margin-top: 30px;
  margin-left: 50px;
  font-size: large;
  font-weight: bolder;
  color: black;
}

.c-but {
  margin-right: 100px;
  margin-left: 100px;
  margin-top: 25px;
  background-color: #4200FF;
  /* position: absolute; */
  /* Green */
  border: none;
  color: white;
  /* padding: 15px 32px; */
  /* text-align: center; */
  text-decoration: none;
  display: absolute;
  top: 10px;
  right: 1px;
  font-size: 17px;
  border-radius: 7px;
  height: 40px;
  font-weight: bold;
  width: 115px;
  /* letter-spacing: 1px; */
  box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.4);
}

.hm {
  display: flex;
}

.h-left {
  flex: 1 1;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  /* align-items: center; */

  /* height: 100%;
    width: 100%; */
}

.h-right {
  display: flex;
  justify-content: center;
  /* height: 92vh; */
  /* width: 100%; */
  flex: 1 1;
  /* background-color: red; */
}

.heading {
  margin: 95px;
}

h1 {
  color: #4200FF;
  font-size: 60px;
  font-weight: bolder;
}

h2 {
  color: #4200FF;
  font-size: 40px;
  font-weight: 900;
}

.btnservices {
  background-color: #4CAF50;
  /* Green */
  border: none;
  color: white;
  padding: 12px 32px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 15px;
  font-weight: bold;
  margin: 3px 2px;
  transition-duration: 0.4s;
  cursor: pointer;
  background-color: #FFD6EE;
  color: #4200FF;
  border: 3px solid #4200FF;
  border-radius: 7px;
}

.btnservices:hover {
  background-color: #4200FF;
  /* color: white; */
}
.btnservicess {
  display: none;
  background-color: #4CAF50;
  /* Green */
  border: none;
  color: white;
  padding: 12px 32px;
  text-align: center;
  text-decoration: none;
  /* display: inline-block; */
  font-size: 15px;
  font-weight: bold;
  margin: 3px 2px;
  transition-duration: 0.4s;
  cursor: pointer;
  background-color: #FFD6EE;
  color: #4200FF;
  border: 3px solid #4200FF;
  border-radius: 7px;
}

.btnservicess:hover {
  background-color: #4200FF;
  /* color: white; */
}

.lottiee {
  /* position: absolute; */
  margin-top: 0px;
}

.overlay {
  display: flex;
  flex-direction: row;
  height: 65vh;
  width: 80%;
  /* background-color: red; */
  z-index: 9;
  margin: 30px;
  /* background: #009938; */
  position: absolute;
  top: 40%;
  left: 10%;
  grid-column-gap: 0px;
  -webkit-column-gap: 0px;
          column-gap: 0px;
  box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.9);

}

.oc1 {
  /* border-radius: 10px; */
  height: 100%;
  flex: 1 1;
  background-color: #4200FF;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.oc2 {
  height: 100%;
  flex: 1 1;
  background-color: #BABAFF;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

}

.oc3 {
  height: 100%;
  flex: 1 1;
  background-color: #4200FF;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.oc1 img {
  overflow: hidden;
  opacity: 0.95;
  /* object-fit: contain; */
  height: 100%;
  width: 100%;
}

.oc1 h2 {
  margin: 30px;
  /* z-index: 1; */
  /* position: relative; */
  color: #FFD6EE;
}

.oc2 h2 {
  margin: 30px;
  /* z-index: 1; */
  /* position: relative; */
  color: #FFD6EE;
}

.oc3 h2 {
  margin: 30px;
  /* z-index: 1; */
  /* position: relative; */
  color: #FFD6EE;
}

.oc1 p {
  margin: 30px;
  font-size: large;
  /* z-index: 1; */
  /* position: relative; */
  color: white;
  margin-bottom: 150px;
}

.oc2 p {
  margin: 30px;
  font-size: large;

  /* z-index: 1; */
  /* position: relative; */
  color: white;
  margin-bottom: 150px;
}

.oc3 p {
  margin: 30px;
  font-size: large;

  /* z-index: 1; */
  /* position: relative; */
  color: white;
  margin-bottom: 150px;
}

.oservices {
  position: absolute;
  bottom: 16%;
  /* z-index: 100; */
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 21vh;
}

.oservices h2 {
  margin-left: 50px;

}

.ser {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  flex-flow: row wrap;

}

.avatardiv {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.avatar {
  height: 160px;
  width: 160px;
  background-color: #4200FF;
  /* flex: 1; */
  border-radius: 50%;
  display: flex;
  justify-content: center;
  margin: auto;

}

.avatar img {
  /* display: block;
  margin-left: auto;
  margin-right: auto; */

  /* margin: auto; */
  object-fit: contain;
  max-width: 60%;
  height: auto;


}

.avatardiv h3 {
  font-size: 200%;
  margin: auto;
  color: #4200FF;
  margin-top: 10px;
}

.about {
  height: 100vh;
  background-color: #BABAFF;
  display: flex;
  /* justify-content: center; */
}

.about h1 {
  color: #f12d9f;
  margin: 100px;
  margin-top: 150px;
}

.about p {
  width: 500px;
  margin: 0px 100px 100px;
  margin-top: 0px;
  color: #f12d9f;
  font-size: 25px;
  font-weight: bolder;

}

.ab-left {
  flex: 1 1;
}

.ab-right {
  flex: 1 1;
}

.contact {
  height: 100vh;
  position: relative;
}

.c-bg {
  width: 20px;
  height: 100%;
  /* background-color: #f12d9f; */
  background-color: #4200FF;

  position: absolute;
  top: 0;
  left: 0;
}

.c-wrapper {
  display: flex;
  padding: 50px;
}

.c-left {
  flex: 1 1
}

.c-right {
  flex: 1 1;
  display: flex;
  /* align-items: center; */
  flex-direction: column;
  justify-content: center;

}

.c-title {
  font-size: 60px;
  width: 80%;
  /* color: #f12d9f; */
  color: #4200FF;

}

.c-info-item {
  display: flex;
  align-items: center;
  font-weight: 300;
}

.c-icon {
  margin: 30px 5px;
  width: 30px;
  height: 30px;
  /* margin: 100px; */
}

.c-info-item a {
  /* text-decoration: none; */
  color: black;
}

.c-desc {
  font-size: 40px;
}

form {
  margin-top: 20px;
}

input {
  width: 50%;
  height: 50px;
  border: none;
  border-bottom: 1px solid black;
  margin: 10px 0px;
  font-size: 14px;
  padding-left: 10px;
}

textarea {
  width: 100%;
  margin: 10px 0px;
  font-size: 14px;
  padding-left: 10px;
}

.c-button {
  margin-right: 100px;
  /* margin-left: 100px; */
  margin-top: 25px;
  background-color: #4200FF;
  /* position: absolute; */
  /* Green */
  border: none;
  color: white;
  /* padding: 15px 32px; */
  /* text-align: center; */
  text-decoration: none;
  display: inline-block;
  font-size: 17px;
  border-radius: 7px;
  height: 40px;
  font-weight: bold;
  width: 115px;
  /* letter-spacing: 1px; */
  box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.4);
}

.lnk {
  text-decoration: none;
  color: #FFD6EE;
}
.lnkk{
  text-decoration: none;
 color: #4200FF;
}
.btlnk{
  text-decoration: none;
  color: #4200FF;
  /* position: ; */
}
.btlnk:hover{
  color: #FFD6EE;
}
.hamb{
  width: 25px;
  height: 25px;
  position: absolute;
  top: 25px;
  left: 20px;
  /* display: none; */
}

.nav-btn{display: none;
}

@media screen and (max-width:720px) {

  .c-but{
    display: none;
    /* right: 10px; */
  }
  .nav-btn{
    margin: 15px;
    margin-top: 32px;
    display: inline;
    width: 12%;
    /* height: 50px; */
    background-color: #FFD6EE;
    border: none;
  }
  .btnservicess{
    display: inline-block;
  }
  .navbar{
    position: fixed;
    /* z-index: inherit; */
    top: 15;
    background-color:#BABAFF;
    left: 0;
    height: 50vh;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    grid-gap: 1.5rem;
    gap: 1.5rem;
    transition: 1s;
    -webkit-transform:translate(-100vh) ;
            transform:translate(-100vh) ;
  }
  .responsive_nav{
    -webkit-transform:none;
            transform:none;
    /* display: none; */
    display: flex;
  }
  .nav-close-btn{
    position: absolute;
   top: 2rem;
   right: 2rem; 
   /* margin: 8px; */
   margin-top: 15px;
   display: inline;
   width: 12%;
   background-color:#BABAFF;
   border: none;
  }
  .hm{
    /* flex-direction: column; */
  }
  .h-right{
    display: none;
  }
  .h-left{
    justify-content: start;
    margin: 30px;
    height: 100vh;
    /* display: none; */
  }
  .heading{
    margin: 5px;
  }
  .overlay{
    /* flex-direction: column; */
    /* width: 10%; */
    left: 0%;
    top: 42%;
    height: 28vh;
    flex-wrap: wrap;
    grid-row-gap: 10px;
    row-gap: 10px;
    position:absolute;
    box-shadow: 0;
    min-width: 83%;
    box-shadow: none;
  }

  .h2{
    height: 3000vh;
    /* position: relative; */
  }
  .oc1,.oc2,.oc3{
    justify-content: start;
    /* display: none; */
    min-width: 100%;
    /* box-shadow: #f12d9f; */

  }
  .oc2{
    /* box-shadow: #4200FF; */
  }
  .oc1 p{font-size: small;}
  .oc2 p{font-size: small}
  .oc3 p{font-size: small}
  .oservices{
    position: absolute;
    bottom: 7%;
    height: 10vh;
  }
  .oservices h2{
    margin-left: 0px;
    font-size: 200%;
    margin-bottom: 10px;
  }
  .avatar{
    height: 80px;
    width: 80px;
  }
  .avatardiv h3{
    font-size: 100% ;
  }
  .ab-left{
    display: none;
  }
  .about h1{
    margin: 50px 10px 50px 0px;
  }
  .about p{
    margin-left: 100px;
    margin-right: 0px;
    margin: 0px;
    display: inline;
    font-size: 33px;
  }
  .about{
    flex-wrap: wrap;
    padding: 20px;
  }
 .about{
  height: 80vh;
 }
 .c-but{
  margin: 10px;
  width: 110px;

 }
 .nav-el{
  margin-left: 10px;
 }
  /* .lttie{
    height: 100px;
    width: 100px;
    display: none;
  }
   */
   .c-wrapper{
    flex-direction: column;
    
   }
   .c-bg{
    height: 100%;
   }
   .c-title{
    margin-top: 0px;
    margin-left: 5px;
    font-size: 30px;
   }
   .c-info-item{
    margin: 0px;
   }
   form{
    margin-top: 0px;
    margin-left: 5px;
    display: flex;
    flex-wrap: wrap;
   }
   .c-desc{
    margin: 0;
   }
   .hamb{
    display: block;
   }
   /* .nav-el{
    display: none;
   } */
}


